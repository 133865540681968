import React, { useState } from "react";
import socket from "../../socket";
import { CircleSlider } from "react-circle-slider";

export default function ControlScreen() {
  const [value, setValue] = useState(0);
  const handleChange = (val) => {
    setValue(val);
    socket.emit("control:data", val);
  }
  return (
    <section className="control">
      <h1>CONTROL</h1>
      <CircleSlider
        value={value}
        onChange={handleChange}
      />
      <input style={{transform: 'rotate(-90deg)'}}type="range" value={value} onChange={(e) => { handleChange(e.target.value)}} min="0" max="100"/>
    </section>
  );
}
