import React from "react";
import { useSocketIOValue } from "./useSocketIOValue";

import ControlScreen from "./components/ControlScreen/ControlScreen";
import SelectionScreen from "./components/SelectionScreen/SelectionScreen";

import "./style.scss";
import SocketStatusIndicator from "./components/SocketStatusIndicator/SocketStatusIndicator";
import { useSocketIOLatency } from "./useSocketIOLatency";

export default function App() {
  const collections = useSocketIOValue("collections:list", []);
  const isControlling = useSocketIOValue("control:status", false);
  const latency = useSocketIOLatency();
  
  
  return (
    <main className="App">
      <div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
        <SocketStatusIndicator />
      </div>
      <div style={{ position: "absolute", top: "1rem", left: "1rem" }}>
        {latency && latency.toFixed(2)}ms
      </div>
      {isControlling ? (
        <ControlScreen />
      ) : (
        <SelectionScreen collections={collections} />
      )}
    </main>
  );
}
