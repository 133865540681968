import React from "react";

import "./JoinQueueButton.scss";

export default function JoinQueueButton({ active, click }) {
  return (
    <div>
      <button
        onClick={(e) => {
          active && click(e);
        }}
        className={`JoinQueueButton ${active ? "active" : ""}`}
      >
        Join queue
      </button>
    </div>
  );
}
