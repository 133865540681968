import { useState, useEffect } from "react";
import socket from "./socket";

export function useSocketIOStatus() {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const setSocketStatus = () => {
      setStatus(socket.connected);
    };
    socket.on("connect", setSocketStatus);
    socket.on("disconnect", setSocketStatus);
    return () => {
      socket.off("connect", setSocketStatus);
      socket.off("disconnect", setSocketStatus);
    };
  }, [socket]);

  return status;
}
