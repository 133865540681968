import React, { useState } from "react";
import socket from "../../socket";
import CollectionSelection from "../CollectionSelection/CollectionSelection";
import JoinQueueButton from "../JoinQueueButton/JoinQueueButton";
import { useSocketIOValue } from "../../useSocketIOValue";

export default function SelectionScreen({ collections }) {
  const queueStatus = useSocketIOValue("queue:status", false);
  const [selectedCollection, setSelectedCollection] = useState();

  const joinQueueHandler = () => {
    if (selectedCollection) {
      socket.emit("selection:collection", selectedCollection);
      socket.emit("queue:request");
    }
  };

  return (
    <section className="selection">
      <h1>LCF 21 - Telekinesis</h1>
      <p>Selected collection is : {selectedCollection} </p>
      <div>{queueStatus ? "in the queue" : "not in the queue"}</div>
      <CollectionSelection
        collections={collections}
        selectedCollection={selectedCollection}
        selectHandler={setSelectedCollection}
      />
      <p>Now showing: Name / Course</p>
      <p>Waiting time x minutes</p>
      {queueStatus 
        ? <div>You are in the queue, please wait...</div>
        : <JoinQueueButton active={!!selectedCollection} click={joinQueueHandler} />
      }
    </section>
  );
}
