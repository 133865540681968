
import React from "react";
import { useSocketIOStatus } from "../../useSocketIOStatus";

import "./SocketStatusIndicator.scss";

export default function SocketStatusIndicator() {
  const socketStatus = useSocketIOStatus();
  return <div className={`SocketStatusIndicator  ${socketStatus ? 'online' : 'offline'}`}></div>;
}
