import { useState, useEffect } from "react";
import socket from "./socket";

export function useSocketIOLatency() {
  const [latency, setLatency] = useState();

  useEffect(() => {
    const pingInterval = setInterval(() => {
      const start = performance.now();
      // volatile, so the packet will be discarded if the socket is not connected
      socket.volatile.emit("ping", () => {
        const latency = performance.now() - start;
        setLatency(latency);
      });
    }, 5000);
    return () => {
      clearInterval(pingInterval);
    };
  }, [socket]);

  return latency;
}
