import { io } from "socket.io-client";

const socket = io(process.env.SOCKETIO_URL, { autoConnect: false });

// Try connect to the websocket
console.log("connecting to ws...");
const sessionID = localStorage.getItem("sessionID");
socket.auth = { sessionID };
socket.connect();

// Stores session ID
socket.on("session", ({ sessionID, userID }) => {
  console.log(`Connected to session with sessionID=${userID}`);
  socket.auth = { sessionID };
  localStorage.setItem("sessionID", sessionID);
  socket.userID = userID;
});

export default socket;
