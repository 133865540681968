import { useState, useEffect } from "react";
import socket from "./socket";

export function useSocketIOValue(eventName, defaultValue) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    socket.on(eventName, setValue);
    return () => {
      socket.off(eventName, setValue);
    }
  }, [socket, eventName]);

  return value;
}
