import React from "react";
import "./CollectionSelection.scss";

export default function CollectionSelection({ collections, selectedCollection, selectHandler }) {
  if (collections.length == 0) return "";
  return (
    <div className="CollectionSelection">
      {collections.map(({ slug, title }) => (
        <button
          key={slug}
          className={slug === selectedCollection ? 'active' : ''}
          onClick={(e) => {
            selectHandler(slug);
          }}
        >
          {title}
        </button>
      ))}
    </div>
  );
}
